import { isSSRServer } from '@/config';
import { mapState as rootMapState } from 'pinia';
import { useUserStore } from '@/store';
import {
    ROLE_MAP,
    getBuyerRequestionList,
    getSellerRequestionList
} from './const.js';
export default {
    data: function () {
        return {
            unAnswerIndex: -1,
            isFromNewAdRegister: false
        };
    },
    computed: {
        ...rootMapState(useUserStore, {
            user: store => store.user
        }),
        leadTypes() {
            return this.user?.leadTypes || [];
        },
        showNewAdQuestionEnter() {
            if(isSSRServer){
                return false;
            }
            return !!(
                !!this.user &&
                (this.leadTypes?.includes(ROLE_MAP.Buyer) ||
                    this.leadTypes?.includes(ROLE_MAP.Seller)) &&
                this.getCookie('_ads_source') &&
                this.getCookie('old_reg_modal') != 'true' &&
                !window.localStorage.getItem('__ad_questions')
            );
        },
        hasFinishedAnswer() {
            return this.unAnswerIndex < 0;
        },
        showAdQuestionsPop() {
            return !!(
                this.showNewAdQuestionEnter &&
                !this.hasFinishedAnswer &&
                this.isFromNewAdRegister
            );
        }
    },
    methods: {
        initFinishedQuestions(finishedBehavior = []) {
            let questionConfigs = [];
            let isBuyerLeadType = this.leadTypes?.includes(ROLE_MAP.Buyer);
            const describeIndex = finishedBehavior.findIndex(
                item => item.behavior == 'questionDescribeMyself'
            );
            const questionSellingBuyNewIndex = finishedBehavior.findIndex(
                item => item.behavior == 'questionSellingBuyNew'
            );
            if (describeIndex >= 0) {
                isBuyerLeadType = true;
            } else if (questionSellingBuyNewIndex >= 0) {
                isBuyerLeadType = false;
            }

            if (isBuyerLeadType) {
                const describeIndex = finishedBehavior.findIndex(
                    item => item.behavior == 'questionDescribeMyself'
                );
                if (describeIndex >= 0) {
                    const isOwnerHouse =
                        finishedBehavior[describeIndex]?.extraData == 'owner';
                    questionConfigs = getBuyerRequestionList(isOwnerHouse);
                } else {
                    this.unAnswerIndex = 0;
                    return;
                }
            } else {
                if (questionSellingBuyNewIndex >= 0) {
                    const isBuyerHouse =
                        finishedBehavior[questionSellingBuyNewIndex]
                            ?.extraData == 'buyer';
                    questionConfigs = getSellerRequestionList(isBuyerHouse);
                } else {
                    this.unAnswerIndex = 0;
                    return;
                }
            }
            const unAnswerIndex = questionConfigs.findIndex(question => {
                return (
                    finishedBehavior.findIndex(
                        item => item.behavior == question.behavior
                    ) < 0
                );
            });

            this.unAnswerIndex = unAnswerIndex;
        },
        getFinishAdQuestions() {
            this.axiosIns
                .get(
                    '/api-site/questionnaire/leadBehaviorAction?_t=' +
                        Date.now()
                )
                .then(res => {
                    const list = res?.data || [];
                    const finishedBehavior = list.filter(
                        item => item.behavior != 'alreadyValidPhone'
                    );
                    this.initFinishedQuestions(finishedBehavior);
                    this.isFromNewAdRegister =
                        list.findIndex(
                            item => item.behavior == 'registerPopUpType'
                        ) >= 0;
                });
        }
    }
};
