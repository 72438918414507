import util from '../../common/util';
import roleMap from './roleMap';
import popMgr from '@/components/global/popMgr';
import { getStore } from '@/store';

import {
    setRegisStatus,
    registrationEnum,
    resetRegisStatus
} from './register-track';
import advRegister from './advertising-register/register.js';

import axios from 'axios';
import needStopPopup from './stopPopup.js';
import useCmsGlobal  from '../../hooks/useCmsGlobal';

//     // vow  exist

// let hasVow =  document.querySelector('[data-vow=vow_register_mask]');

const styleMap = {
    classic: () =>
        import(
            /* webpackChunkName: "common/sign-log/classic"*/ './classic/index.vue'
        ),
    classicNew: () =>
        import(
            /* webpackChunkName: "common/sign-log/classic-new"*/ './classic-new/index.vue'
        ),
    standard: () =>
        import(
            /* webpackChunkName: "common/sign-log/standard"*/ './standard/index.vue'
        ),
    standardNew: () =>
        import(
            /* webpackChunkName: "common/sign-log/standard-new"*/ './standard-new/index.vue'
        ),
    simpleRegister: () =>
        import(
            /* webpackChunkName: "common/sign-log/simple-register"*/ './simple-register/index.vue'
        ),
    chatRegister: () =>
        import(
            /* webpackChunkName: "common/sign-log/chat-register"*/ './chat-register/index.vue'
        ),
    fashion: () =>
        import(
            /* webpackChunkName: "common/sign-log/fashion"*/ './fashion/index.vue'
        ),
    fashionNew: () =>
        import(
            /* webpackChunkName: "common/sign-log/fashion-new"*/ './fashion-new/index.vue'
        ),
    promotion: () =>
        import(
            /* webpackChunkName: "common/sign-log/promotion"*/ './promotion/index.vue'
        ),
    classicOneStep: () =>
        import(
            /* webpackChunkName: "common/sign-log/classic-one-step"*/ './classic-one-step/index.vue'
        ),
    fashionOneStep: () =>
        import(
            /* webpackChunkName: "common/sign-log/fashion-one-step"*/ './fashion-one-step/index.vue'
        ),
    standardOneStep: () =>
        import(
            /* webpackChunkName: "common/sign-log/standard-one-step"*/ './standard-one-step/index.vue'
        ),
    evaluationRegister: () =>
        import(
            /* webpackChunkName: "common/sign-log/evaluation-register"*/ './evaluation-register/index.vue'
        ),
    broker: () =>
        import(
            /* webpackChunkName: "common/sign-log/broker"*/ './broker/index.vue'
        ),
    brokerNew: () =>
        import(
            /* webpackChunkName: "common/sign-log/broker-new"*/ './broker-new/index.vue'
        ),
    brokerOneStep: () =>
        import(
            /* webpackChunkName: "common/sign-log/broker-one-step"*/ './broker-one-step/index.vue'
        ),
    adNewRegister: () =>
        import(
            /* webpackChunkName: "common/sign-log/advertising-register"*/ './ad-new-register/index.vue'
        ),
    advRegister: () =>
        import(
            /* webpackChunkName: "common/sign-log/advertising-register"*/ './advertising-register/index.vue'
        )
    // AM-292884
};

const psMap = {};

let fromCmsOptions = {};
if (util.inCmsPreview) {
    fromCmsOptions = {
        allowClose: true
    };
}

//  send  vow  s mail
export const sendVowEmail = ({ user, isResend = false }) => {
    user = user || getStore('user').user;
    if (!user) {
        console.warn(`user is empty`);
        return;
    }

    let params = new FormData();
    params.append('originUrl', encodeURIComponent(location.href));
    return axios
        .post('/api-site/vow-listings/send-activation-mail', params)
        .then(res => {
            if (res.status.code === 0) {
                let toastMsg = isResend
                    ? window.siteGlobalization.$t('common:register.verifyYourEmailAgain')
                    : window.siteGlobalization.$t(
                          'common:register.pleaseVerifyYourEmail',
                          { account: user.account }
                      );
                util.toast(toastMsg, 3000);
            } else {
                console.log('Sent Email Failed.');
            }
            return res;
        })
        .catch(e => Promise.reject(e));
};

const hasVow = function () {
    if (getStore('user').user) {
        return false;
    }
    return !!document.querySelector('[data-vow=vow_register_mask]');
};

const signLog = {
    async authByUrl() {
        // xxx
        let auth = util.getQueryField('facebook_app_auth_data');
        if (!auth) {
            return;
        }
        try {
            let authData = JSON.parse(auth);
            signLog.register(
                { facebook_app_auth_data: authData },
                '',
                true,
                false,
                false,
                false
            );
            util.setQueryField({ facebook_app_auth_data: '' }, true);
        } catch (e) {
            console.error('url from facebook_app_auth_data error', e);
        }
    },
    setTackState(isAutoPop, isGoogleOneStep) {
        resetRegisStatus();
        const triggeringEvent = registrationEnum.triggeringEvent;
        const registrationType = registrationEnum.registrationType;
        setRegisStatus({
            triggerEvent: isAutoPop
                ? triggeringEvent.auto
                : triggeringEvent.click
        });
        isGoogleOneStep &&
            setRegisStatus({ registrationType: registrationType.chromeApi });
    },
    prepare(style = window.sitePrepareData().registration.style) {
        if (
            util.getCookie('_ads_source') &&
            util.getCookie('old_reg_modal') != 'true'
        ) {
            style = 'adNewRegister';
        }
        if (!psMap[style]) {
            psMap[style] = styleMap[style]();
        }
        return psMap[style];
    },
    getFbData(options, style) {
        const styleArr = ['simpleRegister', 'chatRegister'];
        if (
            util.getCookie('_ads_source') === '15' &&
            window.sitePrepareData().FBAppId &&
            !styleArr.includes(style)
        ) {
            options.registerClass = 'fb-lead-form';
            options.registerTitle = window.siteGlobalization.$t(
                'common:register.fbRegisterTitle'
            );
            options.registerSubTitle = window.siteGlobalization.$t(
                'common:register.fbRegisterSubTitle'
            );
        }
    },
    getVowData(options = {}) {
        if (!hasVow()) {
            return;
        }
        const onregister = options.onregister;
        options.onregister = (...args) => {
            sendVowEmail({ user: args[0] });
            if (onregister) {
                return onregister(...args);
            }
        };
    },
    //  opening popup ， while solving is opening ， The automatic pop-up window came out again
    isOpening: false,
    async login(options = {}, style, popReplace = false, action) {
        var globalData = window.sitePrepareData();
        options = useCmsGlobal(options,'registration');
        //  If the login or registration box is currently open ,  The most direct way to exit
        var cur = popMgr.getCurrent();
        if (!popReplace && (cur?.name === 'sign-log' || signLog.isOpening)) {
            return;
        }
        style = style || globalData?.registration?.style;
        if (globalData.leadType) {
            options.role = roleMap[globalData.leadType];
        }
        this.getFbData(options, style);
        this.getVowData(options);
        const vdata = {
            type: 'login',
            name: 'sign-log',
            optionsFn: popReplace
                ? _ => options
                : lng =>
                      Object.assign(
                          {},
                          globalData.registration,
                          globalData.registration.thirdPart,
                          { allowClose: true },
                          options,
                          getLanguageText(options.languageTextFn, lng),
                          fromCmsOptions
                      )
        };
        try {
            signLog.isOpening = true;
            let pop = await this.prepare(style);
            var ins = popMgr.create(pop.default, vdata, action, true, false);
            await util.sleep(350);
            ins.display(true, false, true);
        } finally {
            signLog.isOpening = false;
        }
    },
    /**
     *
     * @param {*} vm vue root instance
     * @param {*} options
     * @param {*} style
     * @param {*} popReplace
     */
    async register(
        options = {},
        style,
        popReplace = false,
        isAutoPop,
        isGoogleOneStep,
        directOpen = true
    ) {
        var globalData = window.sitePrepareData();
        options = useCmsGlobal(options, style == 'chatRegister' ? 'chatAndCaptureForm' : 'registration');
        if(!util.inCms){
            let { customDisclaimers = {} } = globalData;
            options.openCustomDisclaimers = customDisclaimers?.openCustomDisclaimers;
            options.customDisclaimers = customDisclaimers?.customDisclaimers || [];
        }
        //  If the login or registration box is currently open ,  The most direct way to exit
        var cur = popMgr.getCurrent();
        if (!popReplace && (cur?.name === 'sign-log' || signLog.isOpening)) {
            return;
        }
        style = style || globalData.registration?.style;
        if (
            needStopPopup(
                advRegister.isShow(globalData) ? 'advRegister' : style,
                options
            )
        ) {
            return;
        }
        this.setTackState(isAutoPop, isGoogleOneStep);

        if (isAutoPop && globalData.registration?.autopopup?.triggerType == 4) {
            // landing page promotion  Only in this case is it possible to close the configuration item of the pop-up box
            style = 'promotion';
            options.allowClose =
                globalData.registration.autopopup.allowClose || false;
            options.nameType = globalData.registration.autopopup.nameType || 1;
            options.nameRequired =
                globalData.registration.autopopup.nameRequired || false;
            options.phoneRequired =
                globalData.registration.autopopup.phoneRequired || false;
            let promotionAttrMap = {
                promotionRegisterTitle: 'registerTitle',
                promotionRegisterSubtitle: 'registerSubTitle',
                promotionSubmitText: 'submitRegisterTwo',
                toast: 'toast'
            };
            let curLang = getStore('page').currentLanguage;
            for (let sourceName in promotionAttrMap) {
                let targetName = promotionAttrMap[sourceName]
                options[targetName] = globalData.registration.sysLanguage?.[curLang]?.[sourceName] || globalData.registration[sourceName];
            }
        }
        if (!options.role && globalData.leadType) {
            options.role = roleMap[globalData.leadType];
        }

        //  If there is an advertisement registration configuration, use the advertisement registration method
        if (advRegister.isShow(globalData)) {
            style = 'advRegister';
            options.allowClose = options?.mouseLeaveAllowClose || false;
        }

        options.popStyle = style;
        this.getFbData(options, style);
        this.getVowData(options);
        const vdata = {
            type: 'register',
            name: 'sign-log',
            optionsFn: popReplace
                ? _ => options
                : lng =>
                      Object.assign(
                          {},
                          globalData.registration,
                          globalData.registration.thirdPart,
                          { allowClose: true }, //  Manual trigger can be closed
                          options, //  available at options Bundle allowClose overwrite ,
                          getLanguageText(options.languageTextFn, lng),
                          fromCmsOptions
                      )
        };

        try {
            signLog.isOpening = directOpen;
            let pop = await this.prepare(style);
            var ins = popMgr.create(
                pop.default,
                vdata,
                'closeOtherPop',
                true,
                false
            );
            await util.sleep(350);
            ins.display(directOpen, false, true);
        } finally {
            signLog.isOpening = false;
        }
    }
};

if (!util.isSSRServer){
    window.signLog = signLog;
}

export const mixinsRegistry = ()=>import('@/mixins/register.js');

export default signLog;

function getLanguageText(obj, lng) {
    let ret = {};
    if (!obj) {
        return ret;
    }
    for (let k in obj) {
        ret[k] = obj[k](lng);
    }
    return ret;
}


//  Enter the registered email address in the registration box ， Automatic pop-up login box 
export const openLoginHideOtherPop = (options = {}, email = '') => {
    var globalData = window.sitePrepareData();
    let cur = popMgr.getCurrent();
    //  Exit directly if you have already opened the login box 。
    if (cur && cur.name === 'sign-log' && cur.type === 'login') {
        return;
    }
    options = Object.assign({}, globalData.registration, options);
    let style = globalData.registration && globalData.registration.style;
    // if login successfully
    if (!options.hasOwnProperty('onsuccess')) {
        options.onsuccess = function () {
            popMgr.closeAllPopWin();
            // cur.destroy(false); //close current register popup
        };
    } else {
        let oldOnSuccess = options.onsuccess;
        options.onsuccess = function () {
            oldOnSuccess.call(oldOnSuccess);
            popMgr.closeAllPopWin();
            // cur.destroy(false); //close current register popup
        };
    }
    if (!options.hasOwnProperty('defaultEmail')) {
        options.defaultEmail = email;
    }
    //'hideOtherPop': if click close icon, should show the previous popup;
    return signLog.login(options, style, true, 'hideOtherPop');
};