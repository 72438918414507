import util from '@/common/util';
import popMgr from '@/components/global/popMgr';
import { mapState, mapActions } from 'pinia';
import { useUserStore, usePageStore, getStore } from '@/store';
import verticalRender from './layout/vertical/render';

import { processModuleData } from './init/data/handlerData';
import translate from './common/translate';
import signLog from './components/sign-log';
import {
    setLeadTag,
    loginOrRegister
} from './components/sign-log/socialstudio-lead-tag.js';
import questionNaire from './components/questionnaire';
import { h } from 'vue';
import chat from './components/chat';
import global from './common/global';
import AdQuestionsPop from '@/components/adQuestionsPop/index.vue';
import AdQuestions from '@/components/adQuestionsPop/index.js';
// css分包优化
import('./theme/other.scss');

// app配置， data, computed, render...
export default function getAppConfig() {
    return {
        data: function () {
            return {
                beforeFullScreenScrollY: 0,
                hasSetleadTag: false
            };
        },
        mixins: [AdQuestions],
        computed: {
            // 其他计算属性
            ...mapState(useUserStore, ['user', 'loginStatus']),
            ...mapState(usePageStore, {
                globalData: store => store.page,
                moduleData: store => store.moduleData,
                socialTagType: store => store.socialTagType,
                currentLanguage: store => store.currentLanguage
            })
        },
        render() {
            let fromGlobalSetting = this.globalData.fromGlobalSetting || false;
            if (this.moduleData.length || fromGlobalSetting) {
                return verticalRender({
                    modules: this.moduleData,
                    mCache: this.mCache,
                    globalData: this.globalData,
                    platform: this.platform
                });
            } else {
                return h('div', { class: 'empty-container' }, [
                    h(
                        'p',
                        'This page is still under construction but will be available soon!'
                    )
                ]);
            }
        },
        mounted() {
            document.addEventListener(
                'webkitfullscreenchange',
                this.fullscreenChange,
                false
            );
            if (this.user) {
                if (!this.hasSetleadTag) {
                    setLeadTag(
                        loginOrRegister.login,
                        this.user.id || '',
                        this.user.teamId || ''
                    );
                    this.hasSetleadTag = true;
                }
            } else {
                signLog.authByUrl();
                this.removeAdQuestionSession();
            }

            //  in the case of google translate ,  To start the initialization
            if (
                this.globalData.translatorType === 'googlePlugin' &&
                this.currentLanguage !== 'en'
            ) {
                translate.googleTranslate({ value: this.currentLanguage });
            }
            this.updateCloselyDownloadLink(this.globalData.ownerId || '');
            this.getMlsForClosely();
            this.initAdQuestion();
        },
        watch: {
            user(newVal,oldVal) {
                if (
                    !this.user &&
                    this.globalData.registration &&
                    this.globalData.registration.autopopup &&
                    this.globalData.registration.autopopup.triggerType == 4 &&
                    this.globalData.registration.autopopup.type != 0 &&
                    this.this.pageLocation.query.isPopup != 1
                ) {
                    setTimeout(() => {
                        this.openSignLog({
                            questionMsg: this.$t('common:register.questionMsg')
                        });
                    }, 1000 * 60);
                }
                if (this.user && !this.hasSetleadTag) {
                    setLeadTag(
                        this.socialTagType,
                        this.user.id || '',
                        this.user.teamId || ''
                    );
                    this.hasSetleadTag = true;
                }
                if(this.showNewAdQuestionEnter){
                    this.getFinishAdQuestions();
                }
                if(!this.user){
                    this.removeAdQuestionSession();
                }
                // if(newVal && !oldVal){
                //     this.updateChat();
                // }
            },
            showAdQuestionsPop: {
                handler(val) {
                    this.updateShowAdQuestions(val);
                },
                immediate: true
            }
        },
        methods: {
            ...mapActions(usePageStore, {
                setModuleData: 'setModuleData',
                // handleModuleLng: 'handleModuleLngData',
                handleGlobalLngData: 'handleGlobalLngData',
                handleModuleLngData: 'handleModuleLngData',
                setLanguage: 'setLanguage',
                updateCloselyDownloadLink:'updateCloselyDownloadLink',
                getMlsForClosely:'getMlsForClosely'
            }),
            ...mapActions(useUserStore, {
                updateShowAdQuestions: 'updateShowAdQuestions'
            }),
            getStore,
            fullscreenChange() {
                if (
                    !document.webkitIsFullScreen &&
                    this.beforeFullScreenScrollY
                ) {
                    global.scroll(this.beforeFullScreenScrollY);
                }
            }, 
            updateChat(chatAndCaptureForm, logOutFlag) {
                chat.init({ chatAndCaptureForm, logOutFlag });
            },
            closeChat() {
                chat.destroyChat();
            },
            openCaptureForm(options = {}, popReplace = false) {
                signLog.register(options, 'chatRegister', popReplace);
            },
            openSignLog(options = {}, popReplace = false) {
                this.signLogOptions = options;
                !this.user &&
                    signLog.register(options, options.style, popReplace, true);
            },
            closePop() {
                popMgr.closeAllPopWin();
            },
            changeSignLogStyle(style, popReplace = false) {
                this.signLogOptions &&
                    this.openSignLog(this.signLogOptions, popReplace);
            },
            openQuestionNaire(options = {}, type, popReplace = false) {
                this.questionNaireOptions = options;
                this.questionNaireType = type;
                questionNaire.open({
                    options,
                    type,
                    style: options.style,
                    bind: popReplace,
                    globalData: this.globalData
                });
            },
            changeQuestionNaireType(type, popReplace = false) {
                this.questionNaireOptions &&
                    this.openQuestionNaire(
                        this.questionNaireOptions,
                        type,
                        popReplace
                    );
            },
            refresh(moduleData, pos) {
                // moduleData = processModuleData(this.globalData, moduleData);
                // // store.commit(`page/${SET_MODULEDATA}`, moduleData);
                // // store.commit(`page/${HANDLE_MODULE_LNG_DATA}`);
                // this.setModuleData(moduleData);
                // this.handleModuleLng();

                // if (!isNaN(pos)) {
                //     this.$nextTick(() => {
                //         util.winScrollTop(pos);
                //     });
                // }
            },
            fillModuleDataProp(data) {
                processModuleData(data, this.globalData);

                // const prefix = new Date().getTime();
                // const id = data.id || `${prefix}-${index}`;
                // const theme = data.data.theme || 'standard';
                // data = registerStoreModule(data, this.$store);
                // // const storeName = generateModuleName(data.name);
                // return Object.assign(data, {
                //     id,
                //     theme,
                //     module: data.module || true,
                //     visible: data.visible || false,
                //     moduleLoad: false,
                //     moduleRender: false,
                //     moduleMounted: false,
                //     componentName: data.name + '-' + theme,
                //     // vdata: {
                //     //     props: { id: id },
                //     //     key: id
                //     // },
                //     // getVdata(self, item) {
                //     //     return { ...item.vdata };
                //     // },
                //     data: Object.assign(
                //         {},
                //         data.commonData || {},
                //         data.data || {}
                //     )
                // });
            },
            changeMouduleTheme(moduleId, theme) {
                const moduleData = this.moduleData,
                    idx = util.indexOf(moduleData, moduleId, 'id');
                let module = moduleData[idx];
                module.theme = theme;
                module.data.theme = theme;
                module.componentName = module.name + '-' + theme;
                module.visible = false;
                module.moduleLoad = false;
                module.moduleRender = false;
                module.moduleMounted = false;
                if (module.loadingPs) {
                    module.loadingPs = null;
                }
                this.$forceUpdate();
            },
            moduleRefresh(moduleId) {
                const moduleData = this.moduleData;
                const idx = util.indexOf(moduleData, moduleId, 'id');
                const prefix = new Date().getTime();
                const id = `${prefix}-${idx}`;
                moduleData[idx].id = id;
                // moduleData[idx].vdata.props.id = id;
                // moduleData[idx].vdata.key = id;
            },
            componentRefresh(moduleId, theme) {
                // const moduleData = this.moduleData;
                // const idx = util.indexOf(moduleData, moduleId, 'id');
                // const componentName = moduleData[idx]['componentName'];
                // this.$options.components[componentName] = null;
                // 重新载入组件
                this.changeMouduleTheme(moduleId, theme);
            },
            getModuleDataById(moduleId) {
                let moduleIndex = this.moduleData.findIndex(item => item.id === moduleId);
                for(let i = 0; i <= moduleIndex; i ++){
                    !this.moduleData[i].noObserve &&
                    (this.moduleData[i].noObserve = true);
                }
                return this.moduleData[moduleIndex]
            },
            getModuleById(moduleId) {
                const moduleData = this.getModuleDataById(moduleId);
                if (moduleData) {
                    return moduleData.vm;
                }
            },
            getModuleByName(name, theme) {
                const moduleData = this.moduleData.find(item => {
                    if (name === item.name) {
                        if (theme) {
                            if (theme === item.theme) {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    }
                });
                return moduleData && moduleData.vm;
            },
            getCurPop: function () {
                return popMgr.getCurrent();
            },
            /**鼠标移出浏览器可视区时触发事件 CHIME-13995 */
            mouseLeaveView() {
                signLog.register({ mouseLeaveAllowClose: true });
            },
            changeLanguage(lng, { needRefresh } = {}) {
                if (needRefresh) {
                    // this.setLanguage(lng);
                    window.location.reload();
                } else {
                    this.setLanguage(lng);
                    // 全局数据：用户可编辑文案
                    this.handleGlobalLngData();
                    // 模块：用户可编辑文案
                    this.handleModuleLngData();
                    // 代码内部文案
                    window.siteGlobalization.changeLanguage(this.currentLanguage);
                    /**
                     * 注意时序问题
                     * 在处理完site全站数据后，再通知vuex
                     * 让所有监听该属性的触发可以获取到的数据是切换后的数据
                     */
                    !util.inCmsSetting && this.updateChat();
                }
            },
            openAdQuestionPop() {
                popMgr.create(AdQuestionsPop, {
                    describeToRequestion: true
                });
            },
            removeAdQuestionSession(){
                localStorage.removeItem('__ad_questions');
                localStorage.removeItem('__register__ad_questions');
            },
            initAdQuestion(){
                if (this.showNewAdQuestionEnter) {
                    this.getFinishAdQuestions();
                }
                this.throttleOpenAdQuestionPop = util.throttle(() => {
                    if (!this.showAdQuestionsPop) {
                        return;
                    }
                    const time_value = localStorage.getItem('__register__ad_questions')
                    if (time_value && Date.now() - Number(time_value) < 3000) {
                        localStorage.removeItem('__register__ad_questions');
                        return;
                    }
                    const viewportHeight = window.innerHeight;
                    const scrollY = window.scrollY;
                    if (scrollY > viewportHeight) {
                        if (!localStorage.getItem('__ad_questions')) {
                            this.openAdQuestionPop();
                            global.scroll(this.throttleOpenAdQuestionPop,true);
                        }
                    }
                }, 1000);
                global.scroll(this.throttleOpenAdQuestionPop);
            }
        },
        unmounted() {
            document.removeEventListener(
                'webkitfullscreenchange',
                this.fullscreenChange,
                false
            );
            global.scroll(this.throttleOpenAdQuestionPop,true);
        }
    };
}